import Button from "../../../../common/button";
import Particle from "../../../../common/particle/v2";

import bannerThumb1 from "../../../../assets/images/banner/Item1.png";
import bannerThumb2 from "../../../../assets/images/banner/Item2.png";
import bannerThumb3 from "../../../../assets/images/banner/Item3.png";
import BannerStyleWrapper from "./Banner.style";
import { Link } from "react-router-dom";
const Banner = () => {
  
  return (
    <BannerStyleWrapper className="socialler_v3_baner_sect" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-image-area3">
              <Particle />
              <img
                className="banner-image banner-image1"
                src={bannerThumb1}
                alt=""
              />
              <img
                className="banner-image banner-image2"
                src={bannerThumb2}
                alt=""
              />
              <img
                className="banner-image banner-image3"
                src={bannerThumb3}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-conent3">
              <h4 className="banner-subtitle text-uppercase">
                ¡ Separa tu usuario <span className="red-color">Ahora !</span>
              </h4>
              <h1 className="banner-title text-uppercase">
                Preregistro disponible!
              </h1>
              <div className="banner-count-inner d-flex align-items-center">
                <div className="socialler_v3_baner_buttons">
                <Link to={'/preregistro'}>
                <Button
                    lg
                    variant="mint"
                  >
                  
                   Preregistrate ahora
                  </Button>
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerStyleWrapper>
  );
};

export default Banner;
