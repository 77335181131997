import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/ab-img2.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "Los clubes tienen como objetivo acumular la mayor cantidad de puntos a través de la creación de contenido, así como la interacción de sus miembros en Socialler. Cada miembro recibe puntos por su participación en la creación de contenido, y estos puntos se suman al total del club. Al finalizar el año, el club con la mayor cantidad de puntos recibirá una recompensa, al igual que sus principales creadores de contenido. Se proporcionará más información en el futuro.",
  aboutDescription2:
    'Las insignias en Socialler son de gran importancia, ya que cada acción, logro y actividad desbloqueará diferentes insignias que se mostrarán en el perfil del usuario. En este caso, se mencionarán las insignias pertenecientes a la categoría "Familias", las cuales son especialmente relevantes, ya que tenerlas indica que el usuario es leal, activo y comprometido con Socialler.',
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Gregory Fasabi",
      role: "Fundador y Director General",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Eduardo González",
      role: "Co-Fundador y CTO",
    },
  ],
};

export default data;
