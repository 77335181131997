import character1 from "../../images/clubes/SAPIENTUM(1).png";
import character2 from "../../images/clubes/DEORUM(1).png";
import character3 from "../../images/clubes/FELIUM(1).png";
import character4 from "../../images/clubes/NOVUSORDO(1).png";
import character5 from "../../images/clubes/DEORUM(1).png";
import character6 from "../../images/clubes/SAPIENTUM(1).png";

const data = [
  character1,
  character2,
  character3,
  character4,
  character5,
  character6,
  character3,
  character5,
];

export default data;
