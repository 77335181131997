import Button from "../../../common/button";
import phoneIcon from "../../../assets/images/icon/call-outgoing.svg";
import msgIcon from "../../../assets/images/icon/sms-notification.svg";
import ContactStyleWrapper from "./Contact.style";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import API from "../../../http/client";
import { FaSpinner } from "react-icons/fa";

const Contact = () => {
  const navigate = useNavigate();
  const REF_FULL_NAME = useRef(null);
  const REF_EMAIL = useRef(null);
  const REF_CELL_PHONE = useRef(null);
  const REF_MESSAGE = useRef(null);
  const GET_FORM_VALUE = () => {
    const FULL_ = REF_FULL_NAME.current.value;
    const EMAIL_ = REF_EMAIL.current.value;
    const CELL_PHONE_ = REF_CELL_PHONE.current.value;
    const MESSAGE_ = REF_MESSAGE.current.value;
    return {
      message: MESSAGE_, fullname: FULL_, email: EMAIL_, cellphone: CELL_PHONE_};
  };
  const [loading, setloading] = useState(false)
  return (
    <ContactStyleWrapper>
      <div className="container">
        <div className="section_tag_line">
          <h4 className="text-uppercase">¡DINOS HOLA!</h4>
          <h2 className="text-uppercase">
           NOS ENCANTARÍA HABLAR CONTIGO
          </h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="left_content">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_box">
                  <label htmlFor="name">TU Nombre *</label>
                  <input
                    type="text"
                    placeholder="e.g.  Roe Smith"
                    name="name"
                    ref={REF_FULL_NAME}
                  />
                </div>

                <div className="form_box">
                  <label htmlFor="mail">Correo Electrónico *</label>
                  <input
                    type="mail"
                    placeholder="e.g.  example@gmail.com"
                    name="mail"
                    ref={REF_EMAIL}
                  />
                </div>
                <div className="form_box">
                  <label htmlFor="phnNo">Número Teléfonico *</label>
                  <input
                    type="text"
                    placeholder="e.g.  +99 999-999-99-99"
                    name="phnNo"
                    ref={REF_CELL_PHONE}
                  />
                </div>

                <div className="form_box">
                  <label htmlFor="message">Mensaje *</label>
                  <textarea
                    name="message"
                    placeholder="Escribe tu mensaje"
                    ref={REF_MESSAGE}
                  ></textarea>
                </div>

                <Button lg variant="mint" disabled={loading} onClick={async () => {
                  const data_form = GET_FORM_VALUE();
                    if (
                      data_form.cellphone !== "" &&
                      data_form.email !== "" &&
                      data_form.message !== "" &&
                      data_form.fullname !== ""
                    ) {
                      
                      setloading(true);
                      API.contactForm(data_form).then((result) => {
                        setloading(false);
                        if (result.errors) {
                          const { errors } = result;
                          const entries = Object.values(errors);
                          entries.forEach((value) => {
                            toast.error(value[0]);
                          });
                        }
                        if(result.success){
                          toast.success(result.success);
                          navigate('/inicio')
                        }
                      });
                    } else {
                      toast.error("Rellena todos los campos por favor.");
                    }
                }}>
                  { loading ? (<>
                    <FaSpinner className="spinner" /> Enviando...
                  </>) : (<>
                    Enviar
                  </>)}
                </Button>
              </form>
            </div>
          </div>

          <div className="col-md-6">
            <div className="right_content">
              <h4 className="uppercase widget_title">Información de contacto</h4>
              <div className="contact_address_info">
                <span>
                  <img src={msgIcon} alt="icon" className="img-fluid" />
                </span>
                <div className="contact_info_text">
                  <h5>Envíanos un correo</h5>
                  <p>Soporte: support@thesocialler.com</p>
                  <p>Preguntas: questions@thesocialler.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContactStyleWrapper>
  );
};

export default Contact;
