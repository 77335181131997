import React from "react";
import Layout from "../common/layout";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1";
import BannerStyleWrapper from "../components/section/banner/v2/Banner.style";
import Button from "../common/button";
import { FaPatreon, FaPaypal, FaKickstarter } from "react-icons/fa";
import SectionTitle from "../common/sectionTitle";
import Footer from "../components/section/footer/v3";
import { seoDescription } from "../assets/seo";

export default function DonacionesScreen() {
  return (
    <Layout title={'Donaciones'} description={seoDescription.donaciones}>
      <GlobalStyles />
      <Header />
      <BannerStyleWrapper className="socialler_v2_baner_sect" id="home">
        <div className="container card-comx">
          <div className="socialler_v2_baner_content">
            <h2>
              {" "}
              <span className="banner-emot">🎉</span> RED DE COLABORADORES EN{" "}
              <span>PATREON</span>
            </h2>
            <p className="highlighted px-4 text-center">
            Contamos con una comunidad de colaboradores increíbles que nos ha ayudado en la creación de nuestra red social a través de Patreon y PayPal. Estamos agradecidos por su generosidad y apoyo constante, lo que nos permite continuar desarrollando una plataforma emocionante y con un propósito único. cada uno de nuestros colaboradores tiene un papel importante en el crecimiento de nuestra red social, y sus donaciones nos permiten dedicar más tiempo y recursos para hacer que la experiencia del usuario sea lo mejor posible.
            </p>
            <div className="row" style={{
              flex: 1,
              justifyContent: 'center',
              gap: 20
            }}>
              <div className="col-lg-2 socialler_v2_baner_buttons text-center">
                <a
                  href="https://www.patreon.com/socialler"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button lg variant="outline" className="connect_btn">
                    <FaPatreon />
                    ¡Quiero colaborar!
                  </Button>
                </a>
              </div>
              <div className="col-lg-2 socialler_v2_baner_buttons text-center">
                <a
                  href={"https://www.paypal.com/paypalme/thesocialler"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button lg variant="outline" className="connect_btn">
                    <FaPaypal />
                    Paypal
                  </Button>
                </a>
              </div>
              <div className="col-lg-2 socialler_v2_baner_buttons text-center">
                <a
                  href={"#"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button lg variant="mint" className="connect_btn">
                    <FaKickstarter />
                    Kickstarter
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </BannerStyleWrapper>
      <div className="col-md-6 offset-md-3 col-xs-12 text-center">
        <SectionTitle isCenter={true} title={"MONTO RECAUDADO"} />
        <p className="text-muted px-4 text-center">
          Hemos estado trabajando en la creación de Socialler con el apoyo de
          nuestra increíble comunidad de colaboradores. Estamos muy agradecidos
          por su generosidad, que ha permitido financiar la investigación y el
          desarrollo de esta emocionante plataforma. Nos complace anunciar que,
          gracias a las donaciones recibidas, hemos logrado recaudar una
          cantidad significativa de fondos y estamos ansiosos por continuar este
          viaje emocionante hacia la creación de una nueva y emocionante
          experiencia en las redes sociales.
        </p>
        <SectionTitle isCenter={true} subtitle={"USD"} title={"$ 1,144.01"} />
      </div>
      <Footer />
    </Layout>
  );
}
