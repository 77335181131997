const RoadMapItem = ({ progressIcon, progress, title, text, thumb }) => {
  return (
    <div className="socialler_roadmap_item"> 
      <div className="socialler_roadmap_content"> 
        <div className="socialler_roadmap_text">
          <div className="roadmap_heading">
            <h5 className="mb-15"> {title} </h5>
          </div>
          <p>{text}</p>
        </div>
      </div>
      <div className="roadmap_img">
        <img src={thumb} alt={title} />
      </div>
    </div>
  );
};

export default RoadMapItem;
