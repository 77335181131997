import { useRef } from "react";
import Button from "../../../common/button";
import ContactStyleWrapper from "./Contact.style";
import { toast } from "react-toastify";
import API from "../../../http/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

const Separa = () => {
  const REF_FULL_NAME = useRef(null);
  const REF_EMAIL = useRef(null);
  const REF_USER = useRef(null);
  const [disabled, setdisabled] = useState(false)
  const navigate = useNavigate();

  const [suggests, setSuggests] = useState([]);
  const GET_FORM_VALUE = () => {
    const FULL_ = REF_FULL_NAME.current.value;
    const EMAIL_ = REF_EMAIL.current.value;
    const USER_ = REF_USER.current.value;
    return {
      fullname: FULL_,
      email: EMAIL_,
      user: USER_,
    };
  };
  const handleChangeText = (text) => {
    setSuggests([]);
    REF_USER.current.value = text;
  }

  return (
    <ContactStyleWrapper>
      <div className="container">
        <div className="section_tag_line">
          <h4 className="text-uppercase">¡SEPARA TU USUARIO!</h4>
          <h2 className="text-uppercase">OBTÉN TU USUARIO ANTES DE LA BETA</h2>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="left_content">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_box">
                  <label htmlFor="name">TU Nombre *</label>
                  <input
                    type="text"
                    placeholder="e.g.  Joe Allen"
                    ref={REF_FULL_NAME}
                    name="name"
                  />
                </div>

                <div className="form_box">
                  <label htmlFor="mail">Correo Electrónico *</label>
                  <input
                    type="mail"
                    ref={REF_EMAIL}
                    placeholder="e.g.  example@gmail.com"
                    name="mail"
                  />
                </div>

                <div className="form_box">
                  <label htmlFor="phnNo">Tu Usuario</label>
                  <input
                    type="text"
                    ref={REF_USER}
                    placeholder="e.g.  @alguien123"
                    name="phnNo"
                  />
                </div>
                <div className="justify-content-center">
                  {suggests.length > 0 ? (
                    <label className="my-2">Sugerencias de usuario</label>
                  ) : (
                    <></>
                  )}
                  <div className="contact_address_info">
                    <p className="mb-2">
                      {suggests.map((value) => (
                        <span
                          className="badge mx-1 my-1"
                          onClick={() => {
                            handleChangeText(value)
                          }}
                          style={{
                            cursor: 'pointer',
                            background: "#754DFFD9",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          {value}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
                <Button
                  disabled={disabled}
                  onClick={async () => {
                    const data_form = GET_FORM_VALUE();
                    if (
                      data_form.email !== "" &&
                      data_form.fullname !== "" &&
                      data_form.user !== ""
                    ) {
                      setdisabled(true)
                      API.registerUser(data_form).then((result) => {
                        setdisabled(false)

                        if (result.errors) {
                          const { errors } = result;
                          const entries = Object.values(errors);
                          entries.forEach((value) => {
                            toast.error(value[0]);
                          });
                        }
                        if (result.error_type === "user.exists") {
                          const {users, error} = result;
                          toast.error(error);
                          setSuggests(users)
                        }
                        if(result.success){
                          toast.success(result.success);
                          navigate('/inicio')
                        }
                      });
                    } else {
                      toast.error("Rellena todos los campos por favor.");
                    }
                  }}
                  lg
                  variant="mint"
                >
                  { disabled ? (<>
                    <FaSpinner className="spinner" /> Enviando...
                  </>) : (<>
                    Enviar
                  </>)}
                </Button>
              </form>
            </div>
          </div>
          <div className="col-md-8">
            <div className="right_content">
              <h4 className="uppercase widget_title">Reglas de usuario</h4>
              <div className="contact_address_info">
                <div className="contact_info_text my-3 text-muted">
                  <p>
                    • El mínimo de caracteres es de 3, el máximo de 15.
                    <br />
                    • Se permiten guiones abajo (_), puntos (.), números (0-9),
                    letras (mayúsculas y minúsculas).
                    <br />
                    • No se permiten espacios, caracteres acentuados, símbolos,
                    ni otros caracteres especiales.
                    <br />• Los @usuarios de celebridades, famosos, influencers,
                    empresas y organizaciones no están disponibles.
                    <br />• Tenga en cuenta, que si al registrar un correo no existente, la cuenta se eliminará a las 24Hrs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContactStyleWrapper>
  );
};

export default Separa;
