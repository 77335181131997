import checkIcon from "../../images/blog/arrow.png";
import thumb1 from "../../images/insignias/Familia_Aller.png";
import thumb2 from "../../images/insignias/Familia_Platino.png"; 
import thumb3 from "../../images/insignias/Familia_Lunatic.png"; 
import thumb4 from "../../images/insignias/Familia_Insignia.png"; 
import thumb5 from "../../images/insignias/Familia_Beta.png"; 
import thumb6 from "../../images/insignias/Familia_Premium.png"; 
const insignias = [
  {
    progressIcon: checkIcon,
    progress: "10",
    title: "Familia Aller",
    text: "Insignia es exclusiva para los altos mandos de Socialler, como directores y socios.",
    thumb: thumb1,
  },
  {
    progressIcon: checkIcon,
    progress: "20",
    title: "Familia Platino",
    text: "Exclusiva para los administradores y moderadores de Socialler.",
    thumb: thumb2,
  },
  {
    progressIcon: checkIcon,
    progress: "50",
    title: "Familia Lunática",
    text: `Se obtiene al desbloquear la Familia "Insignia, Beta y Premium".`,
    thumb: thumb3,
  },
  {
    progressIcon: checkIcon,
    progress: "75",
    title: "Familia Insignia",
    text: "Esta insignia exclusiva se obtiene al desbloquear 100 insignias.",
    thumb: thumb4,
  },
  {
    progressIcon: checkIcon,
    progress: "75",
    title: "Familia Beta",
    text: `Insignia exclusiva para los usuarios aceptados en el "Early Access" para beta testers.`,
    thumb: thumb5,
  },
  {
    progressIcon: checkIcon,
    progress: "75",
    title: "Familia Premium",
    text: `Exclusiva para los miembros que se suscriben a la versión Premium de Socialler.`,
    thumb: thumb6,
  },
];

export default insignias;
