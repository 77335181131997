import shapeIcon from "../images/icon/mint_live_icon.svg";

const data = [
  {
    icon: shapeIcon,
    text: "México",
  },
  {
    icon: shapeIcon,
    text: "Perú",
  },
  {
    icon: shapeIcon,
    text: "Colombia",
  },
  {
    icon: shapeIcon,
    text: "Ecuador",
  },
  {
    icon: shapeIcon,
    text: "Venezuela",
  },
  {
    icon: shapeIcon,
    text: "Argentina",
  },
  {
    icon: shapeIcon,
    text: "Bolivia",
  },
  {
    icon: shapeIcon,
    text: "El Salvador",
  },
  {
    icon: shapeIcon,
    text: "El Salvador",
  },
  {
    icon: shapeIcon,
    text: "Chile",
  },
  {
    icon: shapeIcon,
    text: "España",
  },
  {
    icon: shapeIcon,
    text: "Países bajos",
  },
];

export default data;
