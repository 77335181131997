import CoinInfoSlider from "../../coinInfoSlider";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import Sussy from "../../../../assets/img/Sussy.png";
import heartIcon from "../../../../assets/images/icon/v5-hart-icon.svg";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row banner_row">
          <div className="col-lg-6 order-1 order-lg-0">
            <div className="socialler_v5_baner_left">
              <h2>
                SOMOS 4,056 PERSONAS <img src={heartIcon} alt="icon" />
              </h2>
              <div className="banner_buttons">
                <Link to={"/preregistro"}>
                  <Button sm variant="outline">
                    ¡Separa ahora!
                  </Button>
                </Link>
              </div>
              <div className="coin-info">
                <span>
                  EL PRE-REGISTRO ESTA {""}
                  <span className="highlighted">DISPONIBLE</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 order-0 order-lg-1">
            <div className="socialler_v5_baner_right">
              <img src={Sussy} alt="SussyAller" />
            </div>
          </div>
        </div>
      </div>

      <CoinInfoSlider />
    </BannerV1Wrapper>
  );
};

export default Banner;
