import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import PageHeader from "../common/pageHeader/v2";
import Footer from "../components/section/footer/v3";
import Separa from "../components/section/separa/Contact";
import { seoDescription } from "../assets/seo";
export default function SeparaScreen() {
    return (
      <Layout title={'Separa tu usuario'} description={seoDescription.preregistro}>
        <GlobalStyles />
        <Header />
        <PageHeader />
        <Separa />
        <Footer />
      </Layout>
    );
  }
  