import { Routes, HashRouter, Route, Navigate } from "react-router-dom";
import {
  ActivateScreen,
  ComunidadScreen,
  ContactoScreen,
  DonacionesScreen,
  InicioScreen,
  LanzamientoScreen,
  NosotrosScreen,
  SeparaScreen,
} from "../screens";
import React from "react";
import ErrorPage from "../screens/ErrorPage";

function App() {

  return (
    <Routes>
    <Route path="/" element={<InicioScreen />} exact />
    <Route path="/inicio" element={<InicioScreen />} exact />
    <Route path="/nosotros" element={<NosotrosScreen />}  />
    <Route path="/comunidad" element={<ComunidadScreen />} />
    <Route path="/donaciones" element={<DonacionesScreen />} />
    <Route path="/contacto" element={<ContactoScreen />} />
    <Route path="/lanzamiento" element={<LanzamientoScreen />} />
    <Route path="/preregistro" element={<SeparaScreen />} />
    <Route path="/activate_account/" element={<Navigate to="/inicio" />} />
    <Route path="*" element={<ErrorPage />} ></Route>
    <Route
      path="/activate_account/:hash"
      exact
      element={<ActivateScreen />}
    />
  </Routes>

  );
}

export default App;
