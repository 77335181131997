import SectionTitle from "../../../../common/sectionTitle";


import AboutStyleWrapper from "./About.style";

const About = ({description, title}) => {
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="v1_about_us_right_sect">
              <SectionTitle title={title} className="text-center" subtitle="" />
              <div className="v1_about_us_right_text">
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
