import { createGlobalStyle } from "styled-components";
import sectionBgImage from "../../assets/images/bg/v5-baner4.jpg";
import bannerBg from "../../assets/images/hero/sussy.gif";

const GlobalStyles = createGlobalStyle` 
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #c2c2c2 #ffffff41;
  }
  .spinner {
    animation: spin infinite 2s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  .Toastify__toast {
border-radius: 25px;
box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;

  }
  .card-comx{
    width: 100%;
    border-radius 50px;
    background-color:rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(16px);
    padding-bottom: 30px !important;
  }
  .cxc1{
    background: url(${bannerBg}) !important;
  }
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
    filter: blur(5px);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 38px;
    border: 0 solid transparent;
  }
::-moz-selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
}
::selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
} 

html,
body {
    font-size: 16px; 
    vertical-align: baseline;
    font-weight: 500;
    line-height: 1.6;
    font-weight: 500;
    overflow-x: hidden; 
    background-color: #040C12 !important;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    margin: 0px;
    line-height: 1.8;  
}
.row-comments{
  background: url(${sectionBgImage});
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 125px;
}

.card-socialler {
    border: 1px solid #ffffff;
    background: #FFFFFFD 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    width: 70%;
    text-align: center;
    padding: 10px;
    margin-top: 40px;
    backdrop-filter: blur(5px); 
    margin: 20px;
    /* Add the blur effect to the background */
  }
  .team_member_img {
    width: 100%;
    height: 350px;
  }

  .team_member_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 768px) {
    .f1, .f2 {
      left: 100px;
    }
    .f5{
      left: -110px,
    }
    .f3 {
      left: 120px;
    }
  }
  
  @media (max-width: 767px) {
    .f1, .f2, .f3, .f5 {
      left: 0;
    }
  }
  @media (max-width: 767px) {
    .card-socialler {
      width: 90%;
    }
  }
.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
  }
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    line-height: 1.35;
    font-family: 'Bakbak One', cursive;
}

a {
    text-decoration: none !important;
    outline: none;
    transition: all .4s;
}


ul, ol {
    list-style: outside none none;
    margin: 0px;
    padding: 0px;
}

html,
body, p, a{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
} 

@media (min-width: 1400px){
    .container{
        max-width: 1200px;
        padding: 0px 20px;
    }
} 

`;

export default GlobalStyles;
