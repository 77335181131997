import checkIcon from "../../images/blog/arrow.png";
import thumb1 from "../../images/clubes/SAPIENTUM(1).png";
import thumb2 from "../../images/clubes/DEORUM(1).png"; 
import thumb3 from "../../images/clubes/FELIUM(1).png";
import thumb4 from "../../images/clubes/NOVUSORDO(1).png";
const clubes = [
  {
    progressIcon: checkIcon,
    progress: "10",
    title: "Sapientum",
    text: "Personas capaces de aprender de ellos mismos con base a su experiencia, compartir sus conocimientos con los demás, tomar de la mejor manera cualquier fracaso y sobre todo un rechazo.",
    thumb: thumb1,
  },
  {
    progressIcon: checkIcon,
    progress: "20",
    title: "Deorum",
    text: "Personas capaces de utilizar su inteligencia para resolver problemas, destacar ante cualquier situación y saber ayudar a quienes más lo necesitan, tomar decisiones inteligentes y aprender de sus errores.",
    thumb: thumb2,
  },
  {
    progressIcon: checkIcon,
    progress: "50",
    title: "Felium",
    text: "Personas capaces de ayudar y buscar soluciones ante problemas sin la necesidad de recurrir a actos pocos éticos, son sinceros frente a cualquier situación y cuidan sus palabras para expresar su punto de vista.",
    thumb: thumb3,
  },
  {
    progressIcon: checkIcon,
    progress: "75",
    title: "Novusordo",
    text: "Personas capaces de enfrentar la realidad por más difícil que sea, tienen la valentía de luchar y no quedarse callados ante las decisiones poco éticas.",
    thumb: thumb4,
  },
];

export default clubes;
