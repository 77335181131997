import React from "react";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import Upcoming from "../components/section/error";
const ErrorPage = () => {
 
  
  return (
    <>
      <Layout>
        <GlobalStyles /> 
        <Header />
        <Upcoming />
      </Layout>
    </>
  );
};

export default ErrorPage;
