import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import Banner from "../components/section/banner/v3";
import CharacterSlider from "../components/section/characterSlider/v3";
import About from "../components/section/about/v3";
import RoadMap from "../components/section/roadMap/v3";
import Team from "../components/section/team/v3";
import CTA from "../components/section/cta/v2";
import Footer from "../components/section/footer/v3";
import data from "../assets/data/about/dataV2";
import data_clubes from "../assets/data/characterSlider/dataV3.js";
import clubes from "../assets/data/custom/clubes";
import insignias from "../assets/data/custom/insignias";
import { seoDescription } from "../assets/seo.js";

const InicioScreen = () => {
  const { aboutDescription1, aboutDescription2 } = data;
  return (
    <>
      <Layout title={'Socialler'} description={seoDescription.inicio}>
        <GlobalStyles />
        <Header />
        <Banner />
        <CharacterSlider data={data_clubes} />
        <About title={"Nuestros Clubes"} description={aboutDescription1} />
        <RoadMap
          data={clubes}
          title={"Nuestros Clubes"}
          description={"Conoce nuestros clubes"}
        />
        <Team />
        <About
          title={"NUESTRAS PRINCIPALES INSIGNIAS"}
          description={aboutDescription2}
        />
        <RoadMap data={insignias} />
        <CTA />
        <Footer />
      </Layout>
    </>
  );
};

export default InicioScreen;
