export const footerLinksV1 = [
  {
    title: "Inicio",
    url: "/",
  },
  {
    title: "Nosotros",
    url: "/nosotros",
  },
  {
    title: "Comunidad",
    url: "/comunidad",
  },
  {
    title: "Donaciones",
    url: "/donaciones",
  },
  {
    title: "Contacto",
    url: "/contacto",
  },
];
