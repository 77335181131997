import {
  FaInstagram,
  FaFacebook,
  FaPatreon,
  FaPaperPlane,
} from "react-icons/fa";
import Button from "../../../common/button";

import hoverShape from "../../../assets/images/icon/hov_shape_s.svg";
import Particle from "./Particles";
import UpcomingStyleWrapper from "./Upcoming.style";
import { useState } from "react";
import { useRef } from "react";
import API from "../../../http/client";
import { toast } from "react-toastify";
import Slider from "react-slick";

const Upcoming = () => {
  const [count, setCount] = useState(getCountdownTime());
  const REF_INPUT_EMAIL = useRef(null);
  const BTN_REF = useRef(null);
  function GET_VALUE_EMAIL() {
    return REF_INPUT_EMAIL.current.value;
  }

  function getCountdownTime() {
    const targetDate = new Date("2023-12-25");
    const now = new Date();
    const timeRemaining = (targetDate.getTime() - now.getTime()) / 1000;

    return Math.max(timeRemaining, 0);
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <UpcomingStyleWrapper>
      <Particle />
      <div className="container-fluid">
        <div className="upcoming_content">
          <h2 className="title">¡ESTAMOS EN FASE ALPHA!</h2>
          <div className="time_count">
            {/* <CountdownTimer {...settings} /> */}

            <div className="note">
              <h5>Deje su correo electrónico para adquirir un cupo en el programa Alpha.</h5>
            </div>
          </div>

          <div className="input_box">
            <input
              ref={REF_INPUT_EMAIL}
              type="email"
              placeholder="Correo Electrónico"
            />
            <button
              ref={BTN_REF}
              onClick={async () => {
                const EMAIL_CONTENT = GET_VALUE_EMAIL();
                if (EMAIL_CONTENT !== "") {
                  BTN_REF.current.setAttribute("disabled", true);

                  API.register_email({
                    email: EMAIL_CONTENT,
                  }).then((result) => {
                    const { message, handler } = result;
                    if (handler === "success") {
                      toast.success(message);
                    } else {
                      BTN_REF.current.removeAttribute("disabled");
                      toast.error(message);
                    }
                  });
                } else {
                  toast.error("Rellena el campo por favor.");
                }
              }}
            >
              {" "}

              Enviar <FaPaperPlane />
            </button>
          </div>

          <div className="cta_btns">
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://www.facebook.com/groups/thesocialler"}
            >
              <Button lg variant="outline">
                {" "}
                <FaFacebook /> ¡Únete al grupo!{" "}
              </Button>
            </a>
          </div>
        </div>
        <div className="social_links">
          <a
            href="https://www.instagram.com/thesocialler/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
            <span className="hover_shape">
              <img src={hoverShape} alt="shape" />
            </span>
          </a>
          <a
            href="https://www.facebook.com/thesocialler/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook />
            <span className="hover_shape">
              <img src={hoverShape} alt="shape" />
            </span>
          </a>
          <a
            href="https://www.patreon.com/socialler/"
            target="_blank"
            rel="noreferrer"
          >
            <FaPatreon />
            <span className="hover_shape">
              <img src={hoverShape} alt="shape" />
            </span>
          </a>
        </div>
      </div>
    </UpcomingStyleWrapper>
  );
};

export default Upcoming;
