import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";
import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaSignInAlt,
  FaTwitter,
  FaWallet,
} from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import Button from "../../../../common/button";
import logo from "../../../../assets/images/logo.png";
import openseaIcon from "../../../../assets/images/icon/opensea.svg";
import { isMetaMaskInstalled } from "../../../../config";
import MobileMenuStyleWrapper from "./MobileMenu.style";
import { Link } from "react-router-dom";

const MobileMenu = ({ mobileMenuhandle }) => {
  return (
    <MobileMenuStyleWrapper className="socialler_mobile_menu">
      <div className="socialler_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="socialler_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="socialler_mobile_menu_list">
          <ul>
            <li className="mobile_menu_hide">
              <Link to="/inicio">Inicio</Link>
            </li>
            <li className="mobile_menu_hide">
              <Link to="/nosotros">Nosotros</Link>
            </li>
            <li className="mobile_menu_hide">
              <Link to="/comunidad">Comunidad</Link>
            </li>
            <li className="mobile_menu_hide">
              <Link to="/donaciones">Donaciones</Link>
            </li>
            <li className="mobile_menu_hide">
              <Link to="/lanzamiento">Spoiler</Link>
            </li>
            <li className="mobile_menu_hide">
              <Link to="/contacto">Contacto</Link>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/thesocialler"
          >
            <FaFacebook />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/thesocialler/"
          >
            <FaInstagram />
          </a>
        </div>
        <Link to={"/preregistro"}>
          <Button sm variant="hovered" className="connect_btn">
            <FaSignInAlt />
            Preregistro
          </Button>
        </Link>
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
