import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../common/layout";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1";
import Contact from "../components/section/activate";
import PageHeader from "../common/pageHeader/v3";
import Footer from "../components/section/footer/v3";
import API from "../http/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

export default function ActivateScreen() {
  let { hash } = useParams();
  const navigate = useNavigate();
  const [expiration, setexpiration] = useState(null)
  useEffect(() => {
    if(hash === ""){
      toast.error("Ingrese un token válido")
      navigate("/inicio");
    }
    API.validate_token({token:hash}).then((result) => {
      if(result.error){
        toast.error(result.error)
        navigate("/inicio");
      }else{ 
        setexpiration(result.expiration)
        toast.info("Ingrese un el token que se le ha enviado a su correo electrónico.")
      }
    })
  }, [])
  
  return (
    <>
      <Layout>
        <GlobalStyles />
        <Header />
        <PageHeader />
        <Contact  expired_date={expiration} hash={hash} />
        <Footer />
      </Layout>
    </>
  );
}
