import {
    FaTwitter,
    FaInstagram,
    FaFacebook,
    FaTiktok,
  } from "react-icons/fa";
  
  
  const socialData = [
    {
      icon: <FaTwitter />,
      url: "https://twitter.com/thesocialler",
    },
    {
      icon: <FaFacebook />,
      url: "https://www.facebook.com/thesocialler",
    },
    {
      icon: <FaTiktok />,
      url: "https://www.tiktok.com/@thesocialler",
    },
    {
      icon: <FaInstagram />,
      url: "https://www.instagram.com/thesocialler/",
    },
  ];
  
  export default socialData;
  