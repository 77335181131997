import TeamMemberStyleWrapper from "./TeamMember.style";
import hoverShape from "../../../../assets/images/icon/hov_shape_L.svg";

const TeamMember = ({ avatar, name, designation, socialLinks }) => {
  return (
    <TeamMemberStyleWrapper>
      <div className="socialler_team_card">
        <div className="team_member_img">
          <img src={avatar} alt="Socialler Miembros" />
        </div>
        <div style={{top: -20, position: 'relative'}}>
        <h3>{name}</h3>
        <h4>{designation}</h4>
        </div>
        <span className="hover_shape hov_shape1">
          <img src={hoverShape} alt="Socialler Miembros" />
        </span>
        
        <span className="hover_shape hov_shape2">
          <img src={hoverShape} alt="" />
        </span>

        
      </div>
    </TeamMemberStyleWrapper>
  );
};

export default TeamMember;
