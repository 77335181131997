import SectionTitle from "../../../../common/sectionTitle";
import TeamMember from "../teamMember";
import data from "../../../../assets/data/team/teamMembersV1";
import TeamStyleWrapper from "./Team.style";

const Team = () => {
  return (
    <TeamStyleWrapper>
      <div className="socialler_team_sect v1_team_sect" id="team">
        <div className="v1_team_overlay">
          <div className="container">
            <SectionTitle
              title="NUESTRO EQUIPO"
              subtitle="MIEMBROS DE SOCIALLER"
              isCenter={true}
              className="text-center"
            />
            <p className="px-xl-5">
              Somos un equipo de programadores, diseñadores e ilustradores que
              trabajamos incansablemente día a día en el desarrollo de
              Socialler. Juntos, combinamos nuestras habilidades y experiencias
              únicas para llevar a cabo nuestra visión y hacer que Socialler sea
              lo mejor que puede ser.
            </p>
            <div className="socialler_team_content">
              <div className="row" style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                {data?.map((member, i) => (
                  <div
                    id={i}
                    key={i}
                    className={`${`col-lg-3 col-md-4 col-sm-6`}`}
                  >
                    <TeamMember {...member} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TeamStyleWrapper>
  );
};

export default Team;
