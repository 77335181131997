import { Helmet } from "react-helmet";
import '../../assets/minified/b5.min.css'
import '../../assets/minified/slick.min.css'
import '../../assets/minified/st.min.css'

const Layout = ({ children, description, title }) => {
  return (
    <>
      <Helmet>
        {/* meta tag*/}
        <meta charset="utf-8" />
        <title>
          {title}
        </title>
        <meta name="description" content={description} />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" /> 
        <link
          href="https://fonts.googleapis.com/css2?family=Bakbak+One&family=Inter:wght@300;400&display=swap"
          rel="stylesheet"
        />
      </Helmet>  
      {children} 
    </>
  );
};

export default Layout;
