import { useEffect, useState } from "react";
import { FaRocket, FaSignInAlt } from "react-icons/fa";
import { MdNotes } from "react-icons/md";
import Button from "../../../../common/button";
import NavWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import logo from "../../../../assets/images/logo.svg";
import { useLocation, Link } from "react-router-dom";
import { Image } from "react-bootstrap";

const Header = () => {
  const location = useLocation();

  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  return (
    <NavWrapper className="socialler_header" id="navbar">
      <div className="container">
        {/* Main Menu Start */}
        <div className="socialler_menu_sect">
          <div className="socialler_menu_left_sect">
            <div className="logo">
              <Link to="/">
                <Image src={logo} alt="Socialler Logo" style={{width: '25%'}} />
              </Link>
            </div>
          </div>
          <div className="socialler_menu_right_sect socialler_v1_menu_right_sect">
            <div className="socialler_menu_list">
              <ul>
                <li>
                  <Link
                    to={"/inicio"}
                    className={
                      location.pathname == "/" || location.pathname == "/inicio"
                        ? "active-link"
                        : ""
                    }
                    href="/inicio"
                  >
                    Inicio
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname == "/nosotros" ? "active-link" : ""
                    }
                    to="/nosotros"
                  >
                    Nosotros
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname == "/comunidad" ? "active-link" : ""
                    }
                    to="/comunidad"
                  >
                    Comunidad
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname == "/donaciones" ? "active-link" : ""
                    }
                    to="/donaciones"
                  >
                    Donaciones
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname == "/contacto" ? "active-link" : ""
                    }
                    to="/contacto"
                  >
                    Contacto
                  </Link>
                </li>
              </ul>
            </div>
            <div className="socialler_menu_btns">
              <button className="menu_btn" onClick={() => handleMobileMenu()}>
                <MdNotes />
              </button>
              <Link to={"/preregistro"}>
                <Button  sm variant="mint" className="connect_btn">
                  <FaSignInAlt /> Preregistro
                </Button>
              </Link>
              <Link to={"/lanzamiento"}>
                <Button sm variant="mint" className="connect_btn">
                  <FaRocket /> Spoiler
                </Button>
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};

export default Header;
