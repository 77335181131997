import { BEARER_TOKEN_2023, __ROUTE__ } from "./config";
const API = {
  register_email: async (
    data = {
      email: "",
    }
  ) => {
    try {
      const response = await fetch(__ROUTE__("register_email"), {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN_2023}`,
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  },
  registerUser: async (
    data = {
      fullname: "",
      email: "",
      user: "",
    }
  ) => {
    try {
      const response = await fetch(__ROUTE__("user_register"), {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN_2023}`,
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  },
  validate_token: async (data = { token: "" }) => {
    try {
      const response = await fetch(__ROUTE__("validate_token"), {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN_2023}`,
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  },
  activateAccount: async (data = { token: "", code: "" }) => {
    try {
      const response = await fetch(__ROUTE__("activateAccount"), {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN_2023}`,
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  },
  contactForm: async (
    data = { message: "", fullname: "", email: "", cellphone: "" }
  ) => {
    try {
      const response = await fetch(__ROUTE__("contactForm"), {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN_2023}`,
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  },
};

export default API;
