import FooterBottom from "./footerBottom/FooterBottom";
import footerLogo from "../../../../assets/images/logo.png";
import btnArrow from "../../../../assets/images/blog/arrow-icon.png";

import FooterStyleWrapper from "./Footer.style";
import { Link } from "react-router-dom";
import API from "../../../../http/client";
import { useRef } from "react";
import { toast } from "react-toastify";
const Footer = () => {
  const REF_INPUT_EMAIL = useRef(null);
  const BTN_REF = useRef(null);
  function GET_VALUE_EMAIL() {
    return REF_INPUT_EMAIL.current.value;
  }

  return (
    <FooterStyleWrapper>
      <div className="top-footer-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer_image">
                <Link to="/inicio">
                  <img src={footerLogo} alt="Socialler Logo" />
                </Link>
                <p>
                  Colabora con las personas mientras te enteras de lo que se
                  habla en el mundo.
                </p>
              </div>
            </div>

            {/* link widgets  */}

            <div className="col-md-6 col-lg-6">
              <div className="footer-menu footer_newsletter">
                <h5 className="menu-title">
                  Suscribete a nuestro canal de novedades
                </h5>
                <div className="form-box">
                  <input
                    type="text"
                    name="text"
                    ref={REF_INPUT_EMAIL}
                    placeholder="Correo eléctronico"
                    required
                  />
                  <button
                    ref={BTN_REF}
                    onClick={async () => {
                      const EMAIL_CONTENT = GET_VALUE_EMAIL();
                      if (EMAIL_CONTENT !== "") {
                        
                        BTN_REF.current.setAttribute("disabled", true);
                        API.register_email({
                          email: EMAIL_CONTENT,
                        }).then((result) => {
                          const { message, handler } = result;
                          BTN_REF.current.removeAttribute("disabled");
                          if (handler === "success") {
                            toast.success(message);
                          } else {
                            toast.error(message);
                          }
                        });
                      }else{
                        toast.error("Rellena el campo por favor.");
                      }
                    }}
                  >
                    <img src={btnArrow} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom  */}
      <FooterBottom />
    </FooterStyleWrapper>
  );
};

export default Footer;
