import React from "react";
import Layout from "../common/layout";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1";
import Banner from "../components/section/banner/v5";
import Footer from "../components/section/footer/v3";
import { seoDescription } from "../assets/seo";

export default function ComunidadScreen() {
  return (
    <Layout title={'Comunidad'} description={seoDescription.comunidad}>
      <GlobalStyles />
      <Header />
      <Banner />
      <Footer />
    </Layout>
  );
}
