import { seoDescription } from "../assets/seo";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import Upcoming from "../components/section/upcoming";
const LanzamientoScreen = () => {
 
  
  return (
    <>
      <Layout title={'Lanzamiento'} description={seoDescription.lanzamiento}>
        <GlobalStyles /> 
        <Header />
        <Upcoming />
      </Layout>
    </>
  );
};

export default LanzamientoScreen;
