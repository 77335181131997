import { useRef } from "react";
import Button from "../../../common/button";
import ContactStyleWrapper from "./Contact.style";
import { FaSearch } from "react-icons/fa";
import API from "../../../http/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Contact = ({ expired_date, hash }) => {
  const REF_CODE = useRef(null);
  const navigate = useNavigate();
  const GET_CODE = () => {
    return REF_CODE.current.value;
  };
  return (
    <ContactStyleWrapper>
      <div className="container">
        <div className="section_tag_line">
          <h4 className="text-uppercase">VERIFICACIÓN DE IDENTIDAD</h4>
          <h2 className="text-uppercase">INGRESA EL CÓDIGO DE VERIFICACIÓN</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="left_content">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_box">
                  <label htmlFor="name">Código de Activación*</label>
                  <input
                    ref={REF_CODE}
                    type="text"
                    placeholder="XXX-XXX-XXX"
                    name="activation_code"
                  />
                </div>
                <Button
                  onClick={async () => {
                    if (GET_CODE() === "") {
                      toast.error("Ingresa el código de validación");
                      return;
                    }
                    API.activateAccount({
                      code: GET_CODE(),
                      token: hash
                    }).then((result) => {
                      if(result.error){
                        toast.error(result.error)
                      }
                      if(result.success){
                        navigate("/inicio");
                        toast.success(result.success)
                      }
                    });
                  }}
                  lg
                  variant="mint"
                >
                  <FaSearch /> Validar
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ContactStyleWrapper>
  );
};

export default Contact;
