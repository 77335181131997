import React from "react";
import Layout from "../common/layout";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1";
import About from "../components/section/about/v1";
import Team from "../components/section/team/v3";
import SectionTitle from "../common/sectionTitle";
import ISOTIPO from "../assets/isotipo/Isotype_Grids.svg";
import SUSSYALLER from "../assets/img/Sussy.png";
import Footer from "../components/section/footer/v4";
import { seoDescription } from "../assets/seo";
export default function NosotrosScreen() {
  return (
    <Layout title={'Nosotros'} description={seoDescription.nosotros}>
      <GlobalStyles />
      <Header />
      <About />
      <Team />
      <div className="col-md-6 offset-md-3 col-xs-12 text-center">
        <SectionTitle
          isCenter={true}
          subtitle={"NUESTRO ISOTIPO"}
          title={"ISOTIPO"}
        />
        <p className="mx-4">
          El logo de Socialler representa la unión de la letra inicial del
          nombre con un círculo que simboliza al usuario. La letra "S" del
          isotipo se utiliza para crear el cuerpo, representando el camino que
          el usuario debe seguir.
        </p>
      </div>

      <div className="row">
        <div className="col-md-4 col-12 offset-md-4">
          <div className="image-container">
            <img src={ISOTIPO} alt="SOCIALLER ISOTIPO" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12 offset-md-3 text-center my-5">
          <SectionTitle isCenter={true} subtitle={""} title={"SUSSY ALLER"} />
          <p className="mx-4">
            Sussy Aller es nuestra asistente virtual amigable y eficiente para
            mejorar la experiencia del usuario en Socialler, proporcionando una
            amplia gama de servicios de asistencia y seguimiento de informes,
            actualizaciones y consultas.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="row">
            <div className="col-md-4">
              <div
                className="card-socialler f1"
                style={{ position: "relative" }}
              >
                <p style={{ opacity: 1 }}>Actualizaciones</p>
                <p style={{ opacity: 0.5 }}>
                  Comunicación de cambios y mejoras en la aplicación Socialler.
                </p>
              </div>
              <div className="card-socialler">
                <p style={{ opacity: 1 }}>Tutoriales</p>
                <p style={{ opacity: 0.5 }}>
                Guías para aprender a utilizar la aplicación correctamente.
                </p>
              </div>
              <div
                className="card-socialler f1"
                style={{ position: "relative" }}
              >
                <p style={{ opacity: 1 }}>Bienvenidas</p>
                <p style={{ opacity: 0.5 }}>
                Saludo amistoso y para los recién llegados a la aplicación.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="image-container">
                <img src={SUSSYALLER} width={"100%"} alt="SUSSY ALLER" />
              </div>
            </div>
            <div className="col-md-4 f5" style={{position:'relative'}}>
              <div
                className="card-socialler f2"
                style={{ position: "" }}
              >
                <p style={{ opacity: 1 }}>Atención única</p>
                <p style={{ opacity: 0.5 }}>
                Manejo de las consultas y reportes de los usuarios.
                </p>
              </div>
              <div
                className="card-socialler f3"
                style={{ position: "relative"}}
              >                <p style={{ opacity: 1 }}>Mentoría</p>
                <p style={{ opacity: 0.5 }}>
                Consejos para un uso responsable de Socialler.
                </p>
              </div>
              <div
                className="card-socialler f2"
                style={{ position: ""}}
              >
                <p style={{ opacity: 1 }}>Spoilers</p>
                <p style={{ opacity: 0.5 }}>
                Próximas funciones, botones y secciones de la aplicación.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
