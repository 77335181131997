import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

import avatarThumb1 from "../../team/Greg.png";
import avatarThumb2 from "../../images/team/eduardo-sd.png";
import avatarThumb3 from "../../team/Mich.jpg";
import avatarThumb4 from "../../team/Vanessa.jpg";
import avatarThumb7 from "../../team/Juanda.jpg";
import avatarThumb8 from "../../team/Miguel.jpg";
import avatarThumb9 from "../../team/Martina.jpg";
import avatarThumb10 from "../../team/Angelica.jpg";
import Gimarha from "../../team/Gimarha.jpeg";
import Maridelmar from "../../team/Mariadelmar Guardo.jpg";

const data = [
  {
    avatar: avatarThumb1,
    name: "Gregory Fasabi",
    designation: "Fundador & CEO",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb2,
    name: "Eduardo González",
    designation: "Co-Fundador & CTO",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb7,
    name: "Juanda Rios",
    designation: "QA (Tester)",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb8,
    name: "Miguel Salcedo",
    designation: "Diseñador Branding",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: Gimarha,
    name: "Gimarha",
    designation: "Artista",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: Maridelmar,
    name: "Maridelmar Guardo",
    designation: "Diseñadora & animadora",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb10,
    name: "Angelica Reyes",
    designation: "Ilustradora 2D",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
];

export default data;
