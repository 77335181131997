import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import PageHeader from "../common/pageHeader/v5";
import Contact from "../components/section/contact";
import Footer from "../components/section/footer/v3";
import { seoDescription } from "../assets/seo";

export default function ContactoScreen() {
  return (
    <Layout title={'Contacto'} description={seoDescription.contacto}>
      <GlobalStyles />
      <Header />
      <PageHeader />
      <Contact />
      <Footer />
    </Layout>
  );
}
